import React from "react";
import "./Visualization.css";
import p5 from "p5";

class Visualization extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  Sketch = (p) => {
    const finishTarget = 1.1;
    const damping = 0.15;

    let moving = false;
    let finishing = true;
    let frameRateSet = false;
    let animationTarget = finishTarget;
    let animation = animationTarget;

    let maxSize;

    p.setup = () => {
      p.createCanvas(p.windowWidth, p.windowHeight);
      maxSize = p.calcMaxSize();

      p.noStroke();
      p.fill(this.props.palette.TWO);
    };

    p.windowResized = () => {
      p.resizeCanvas(p.windowWidth, p.windowHeight);
      maxSize = p.calcMaxSize();
      frameRateSet = false;
      p.redraw();
    };

    p.draw = () => {
      p.background(this.props.palette.ONE);

      if (moving) {
        let timeLeft = this.props.getTimeLeft();

        let factor = Math.max(timeLeft, 0) / this.props.timer;
        animationTarget = factor;
      }

      if (animation > animationTarget) {
        animation = animationTarget;
      } else {
        animation = animationTarget * damping + animation * (1 - damping);
      }

      p.ellipse(
        p.windowWidth / 2,
        p.windowHeight / 2,
        animation * maxSize,
        animation * maxSize
      );

      if (moving && !frameRateSet) {
        if (animation > animationTarget) {
          frameRateSet = true;
          let frameRate = Math.ceil(
            (1000 /
              (this.props.timer /
                (Math.max(p.windowWidth, p.windowHeight) *
                  0.5 *
                  window.devicePixelRatio))) *
              3
          );

          console.log(frameRate);

          p.frameRate(Math.min(frameRate, 60));
        }
      }

      if (finishing) {
        if (animation > 1) {
          finishing = false;
          p.noLoop();
        }
      }
    };

    p.setMoving = (move) => {
      moving = move;
      frameRateSet = false;
      p.frameRate(60);
    };

    p.setFinishing = (finish) => {
      finishing = finish;
      animationTarget = finishTarget;
      p.frameRate(60);
    };

    p.calcMaxSize = () => {
      return Math.sqrt(
        Math.pow(p.windowWidth, 2) + Math.pow(p.windowHeight, 2)
      );
    };
  };

  componentDidMount() {
    this.p5 = new p5(this.Sketch, this.ref.current);
  }

  componentDidUpdate(prevProps) {
    if (this.props.state !== prevProps.state) {
      if (this.props.state === "running") {
        this.p5.loop();
        this.p5.setMoving(true);
      }

      if (this.props.state === "pause") {
        this.p5.noLoop();
        this.p5.setMoving(false);
      }

      if (this.props.state === "start" || this.props.state === "finish") {
        this.p5.loop();
        this.p5.setMoving(false);
        this.p5.setFinishing(true);
      }
    }
  }

  render() {
    return <div ref={this.ref}></div>;
  }
}

export default Visualization;
