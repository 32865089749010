import React from "react";
import "./App.css";

import Visualization from "../Visualization/Visualization.js";
import Sound from "react-sound";

import ControlPanel from "../ControlPanel/ControlPanel.js";
import ControlStart from "../ControlStates/Start/Start.js";
import ControlRunning from "../ControlStates/Running/Running.js";
import ControlPause from "../ControlStates/Pause/Pause.js";
import ControlFinish from "../ControlStates/Finish/Finish.js";
import ControlSettings from "../ControlStates/Settings/Settings";

const TimerState = {
  RUNNING: "running",
  START: "start",
  PAUSE: "pause",
  FINISH: "finish",
  SETTINGS: "settings",
};

const DefaultTimings = {
  "1 Minute": 60000,
  "5 Minuten": 300000,
  "45 Minuten": 2700000,
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timerState: TimerState.START,
      timer: 0,
      timerInitial: Object.values(DefaultTimings)[0],
      start: 0,
      timeLeft: 0,
      isCustom: false,
      customOption: 0,
      playSound: true,
      showMinimal: false,
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleTimerSet = this.handleTimerSet.bind(this);
    this.handleTimeRequest = this.handleTimeRequest.bind(this);
    this.getTimeForVis = this.getTimeForVis.bind(this);
    this.handleSettings = this.handleSettings.bind(this);
  }

  componentDidMount() {
    // https://hackernoon.com/how-to-take-advantage-of-local-storage-in-your-react-projects-a895f2b2d3f2
    for (let key in this.state) {
      if (localStorage.hasOwnProperty(key)) {
        let value = localStorage.getItem(key);
        try {
          value = JSON.parse(value);
          this.setState({ [key]: value });
        } catch (e) {
          this.setState({ [key]: value });
        }
      }
    }
  }

  componentDidUpdate() {
    for (let key in this.state) {
      localStorage.setItem(key, JSON.stringify(this.state[key]));
    }
  }

  handleStateChange(newState) {
    this.setState({ timerState: newState });

    if (newState === TimerState.PAUSE) {
      this.setState({
        timer: this.state.start + this.state.timer - Date.now(),
        start: Date.now(),
      });
    }

    if (newState === TimerState.RUNNING) {
      this.setState({ start: Date.now() });
    }

    if (newState === TimerState.FINISH) {
      this.setState({ timer: this.state.timerInitial });
    }
  }

  handleTimerSet(newValue, isCustom, customOption) {
    this.setState({
      timer: newValue,
      timerInitial: newValue,
      isCustom: false,
    });

    if (isCustom && customOption) {
      this.setState({
        isCustom: true,
        customOption: customOption,
      });
    }
  }

  handleTimeRequest() {
    this.setState({
      timeLeft: this.state.start + this.state.timer - Date.now(),
    });
  }

  handleSettings(args) {
    this.setState({
      playSound: args.playSound,
      showMinimal: args.showMinimal,
    });
  }

  getTimeForVis() {
    return this.state.start + this.state.timer - Date.now();
  }

  render() {
    let component;

    if (this.state.timerState === TimerState.START) {
      component = (
        <ControlStart
          onStart={() => this.handleStateChange(TimerState.RUNNING)}
          onSettings={() => this.handleStateChange(TimerState.SETTINGS)}
          onTimerSet={this.handleTimerSet}
          timings={DefaultTimings}
          default={this.state.timerInitial}
          isCustom={this.state.isCustom}
          customOption={this.state.customOption}
        />
      );
    }

    if (this.state.timerState === TimerState.SETTINGS) {
      component = (
        <ControlSettings
          onBack={() => this.handleStateChange(TimerState.START)}
          onSet={this.handleSettings}
          playSound={this.state.playSound}
          showMinimal={this.state.showMinimal}
        />
      );
    }

    if (this.state.timerState === TimerState.RUNNING) {
      component = (
        <ControlRunning
          onPause={() => this.handleStateChange(TimerState.PAUSE)}
          onFinish={() => this.handleStateChange(TimerState.FINISH)}
          onTimeRequest={this.handleTimeRequest}
          timeLeft={this.state.timeLeft}
          title={this.props.title}
          showMinimal={this.state.showMinimal}
        />
      );
    }

    if (this.state.timerState === TimerState.PAUSE) {
      component = (
        <ControlPause
          onContinue={() => this.handleStateChange(TimerState.RUNNING)}
          onRepeat={() => {
            this.handleStateChange(TimerState.RUNNING);
            this.setState({ timer: this.state.timerInitial });
          }}
          onCancel={() => this.handleStateChange(TimerState.START)}
          timeLeft={this.state.timeLeft}
          title={this.props.title}
        />
      );
    }

    if (this.state.timerState === TimerState.FINISH) {
      component = (
        <div>
          <ControlFinish
            onRepeat={() => this.handleStateChange(TimerState.RUNNING)}
            onCancel={() => this.handleStateChange(TimerState.START)}
            timer={this.state.timerInitial}
          />

          {this.state.playSound && (
            <Sound url="./finish.mp3" playStatus={Sound.status.PLAYING} />
          )}
        </div>
      );
    }

    if (
      !(this.state.showMinimal && this.state.timerState === TimerState.RUNNING)
    ) {
      component = <ControlPanel>{component}</ControlPanel>;
    }

    return (
      <div className="App">
        <Visualization
          palette={this.props.palette}
          timer={this.state.timerInitial}
          getTimeLeft={this.getTimeForVis}
          state={this.state.timerState}
        />
        {component}
      </div>
    );
  }
}

export default App;
