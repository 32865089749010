import React from "react";

import FormatTime from "../../FormatTime/FormatTime.js";
import Time from "../../Time/Time.js";

import Button from "@material-ui/core/Button";

import ReplayIcon from "@material-ui/icons/Replay";
import CloseIcon from "@material-ui/icons/Close";

class ControlFinish extends React.Component {
  constructor(props) {
    super(props);
    this.repeat = this.repeat.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  repeat() {
    if (this.props.onRepeat) {
      this.props.onRepeat();
    }
  }

  cancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render() {
    return (
      <div>
        <div className="controlRow controlRowBottomSpacing">
          <Button onClick={this.cancel} startIcon={<CloseIcon />}>
            Stoppen
          </Button>
        </div>

        <div className="controlRow">
          <Time>{FormatTime(this.props.timer, true)}</Time>

          <Button
            onClick={this.repeat}
            startIcon={<ReplayIcon />}
            variant="contained"
            color="primary"
            disableElevation
          >
            Wiederholen
          </Button>
        </div>
      </div>
    );
  }
}

export default ControlFinish;
