import React from "react";
import "./Settings.css";

import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

class ControlSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playSound: this.props.playSound,
      showMinimal: this.props.showMinimal,
    };
  }

  componentWillUnmount() {
      this.props.onSet({
          playSound: this.state.playSound,
          showMinimal: this.state.showMinimal,
      });
  }

  render() {
    return (
      <div>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              checked={this.state.playSound}
              value="start"
              control={<Switch color="primary" />}
              label="Sound nach Ablauf spielen"
              labelPlacement="start"
              onChange={() => this.setState({playSound: !this.state.playSound})}
            />

            <FormControlLabel
              checked={this.state.showMinimal}
              value="start"
              control={<Switch color="primary" />}
              label="Nur Visualiserung zeigen"
              labelPlacement="start"
              onChange={() => this.setState({showMinimal: !this.state.showMinimal})}
            />
          </FormGroup>
        </FormControl>

        <div className="controlRow">
          <Button className="controlRow" onClick={this.props.onBack}>
            Zurück
          </Button>
        </div>
      </div>
    );
  }
}

export default ControlSettings;
