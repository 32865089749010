const colorPairings = [
  /* Homevoice branding */
  ["#5E40A2", "#EBE8F3"], // pixie dust

  /* Colors from: https://duo.alexpate.uk/ */
  ["#9d4cef", "#f7e773"], // 4 + 6
  ["#2f305e", "#ef374e"], // 8
  ["#624ce0", "#e8effc"], // 17
  ["#cdacd9", "#eedb61"], // 25
  ["#44388d", "#b2d7c8"], // 26
  ["#1461bb", "#f9d4d3"], // 32
  ["#000000", "#f5bdbc"], // 41
  ["#e75054", "#f8dada"], // 57
  ["#f4c115", "#52b9a4"], // 73
];

function Colors() {
  let randomIndex = Math.round(Date.now() / 80000000) % colorPairings.length;
  //let randomIndex = Math.floor(Math.random() * colorPairings.length);
  return colorPairings[randomIndex];
}

export default Colors;
