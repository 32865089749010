import React from "react";
import "./ControlPanel.css";

class ControlPanel extends React.Component {
    constructor(props) {
      super(props);
      this.handleAction = this.handleAction.bind(this);
    }
  
    handleAction(value) {
      this.setState({ step: value });
    }
  
    render() {
      return (
        <div className="controlPanel">
          {this.props.children}
        </div>
      );
    }
  }

  export default ControlPanel;