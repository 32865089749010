import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/App.js";
import * as serviceWorker from "./serviceWorker";

import Colors from "./components/Colors/Colors.js";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const Title = document.title;

let [one, two] = Colors();

const Palette = {
  ONE: one,
  TWO: two,
};

document.querySelector('meta[name="theme-color"]').setAttribute("content", Palette.ONE);

const theme = createMuiTheme({
  palette: {
    primary: { main: Palette.ONE },
  },
  typography: {
    fontFamily: '"IBM Plex Sans", sans-serif',
  },
  overrides: {
    MuiButton: {
      text: {
        color: "rgba(0, 0, 0, 0.6);",
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App palette={Palette} title={Title} />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
