import React from "react";

import FormatTime from "../../FormatTime/FormatTime.js";
import Time from "../../Time/Time.js";
import DocumentTitle from "../../DocumentTitle/DocumentTitle.js";

import Button from "@material-ui/core/Button";

import ReplayIcon from "@material-ui/icons/Replay";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import CloseIcon from "@material-ui/icons/Close";

class ControlPause extends React.Component {
  constructor(props) {
    super(props);

    this.contintue = this.continue.bind(this);
    this.cancel = this.cancel.bind(this);
    this.repeat = this.repeat.bind(this);
  }

  componentDidMount() {
    DocumentTitle(FormatTime(this.props.timeLeft, true) + " (Pause)");
  }

  componentWillUnmount() {
    DocumentTitle(this.props.title);
  }

  continue() {
    if (this.props.onContinue) {
      this.props.onContinue();
    }
  }

  cancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  repeat() {
    if (this.props.onRepeat) {
      this.props.onRepeat();
    }
  }

  render() {
    return (
      <div>
        <div className="controlRow controlRowBottomSpacing">
          <Button onClick={this.cancel} startIcon={<CloseIcon />}>
            Stoppen
          </Button>
        </div>

        <div className="controlRow controlRowBottomSpacing">
          <Button onClick={this.repeat} startIcon={<ReplayIcon />}>
            Wiederholen
          </Button>
        </div>

        <div className="controlRow">
          <Time blink={true}>{FormatTime(this.props.timeLeft)}</Time>

          <Button
            variant="contained"
            color="primary"
            onClick={this.contintue}
            startIcon={<PlayArrowIcon />}
            disableElevation
          >
            Fortfahren
          </Button>
        </div>
      </div>
    );
  }
}

export default ControlPause;
