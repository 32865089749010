import React from "react";
import "./Running.css";

import FormatTime from "../../FormatTime/FormatTime.js";
import Time from "../../Time/Time.js";
import DocumentTitle from "../../DocumentTitle/DocumentTitle.js";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import PauseIcon from "@material-ui/icons/Pause";

class ControlRunning extends React.Component {
  constructor(props) {
    super(props);
    this.pause = this.pause.bind(this);
  }

  getTimerFromTime() {
    if (this.props.timeLeft) {
      return this.props.timeLeft;
    } else {
      return 0;
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
    this.tick();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    DocumentTitle(this.props.title);
  }

  componentDidUpdate() {
    if (this.props.timeLeft <= 0 && this.props.onFinish) {
      this.props.onFinish();
    }
  }

  tick() {
    if (this.props.onTimeRequest) {
      this.props.onTimeRequest();
    }
  }

  pause() {
    if (this.props.onPause) {
      this.props.onPause();
    }
  }

  render() {
    DocumentTitle(FormatTime(this.props.timeLeft, true));

    let component;

    if (!this.props.showMinimal) {
      component = (
        <div className="controlRow">
          <Time>{FormatTime(this.props.timeLeft)}</Time>
          <Button onClick={this.pause} startIcon={<PauseIcon />}>
            Anhalten
          </Button>
        </div>
      );
    } else {
      component = (
        <div className="minimal">
          <IconButton aria-label="pause" onClick={this.pause}>
            <PauseIcon />
          </IconButton>
        </div>
      );
    }

    return <div>{component}</div>;
  }
}

export default ControlRunning;
