function FormatTime(time, showAll) {
  let ms = parseInt(time);

  let s = Math.floor(ms / 1000) % 60;
  let m = Math.floor(ms / 60000) % 60;
  let h = Math.floor(ms / 3600000);


  let output = "";
  if (h > 0 || showAll) output += h + ":";
  if (h > 0 || m > 0 || showAll) output += pad(m, 2) + ":";
  output += pad(s, 2);

  return output;
}

export default FormatTime;

function pad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
