import React from "react";
import "./Start.css";

import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

import TimerIcon from "@material-ui/icons/Timer";
import SettingsIcon from "@material-ui/icons/Settings";

const CUSTOM = "Eigene Zeit";
const CUSTOM_OPTIONS = {
  Sekunden: 1000,
  Minuten: 60000,
  Stunden: 3600000,
};

class ControlStart extends React.Component {
  constructor(props) {
    super(props);
    this.start = this.start.bind(this);
    this.set = this.set.bind(this);
    this.changeValue = this.changeValue.bind(this);
    this.changeCustomOptions = this.changeCustomOptions.bind(this);
    this.changeCustomValue = this.changeCustomValue.bind(this);

    this.props.onTimerSet(this.props.default);

    this.state = {
      value: this.props.default,
      customValue: 10,
      customOption: CUSTOM_OPTIONS.Minuten,
    };
  }

  componentDidMount() {
    if (this.props.isCustom) {
      let customVaule = this.props.default / this.props.customOption;

      this.setState({
        customOption: this.props.customOption,
        customValue: customVaule,
        value: CUSTOM,
      });

      if (this.props.onTimerSet && !isNaN(customVaule)) {
        this.props.onTimerSet(
          this.props.default,
          true,
          this.props.customOption
        );
      }
    }
  }

  start() {
    if (this.props.onStart) {
      this.props.onStart();
    }
  }

  set() {
    if (this.props.onSettings) {
      this.props.onSettings();
    }
  }

  changeValue(e) {
    if (e.target.value === CUSTOM) {
      this.setState({ value: CUSTOM });
      this.props.onTimerSet(
        this.state.customValue * this.state.customOption,
        true,
        this.state.customOption
      );
    } else {
      let newTiming = parseInt(e.target.value);
      this.setState({ value: newTiming });
      if (this.props.onTimerSet) {
        this.props.onTimerSet(newTiming);
      }
    }
  }

  changeCustomOptions(e) {
    let newOption = parseInt(e.target.value);

    this.setState({ customOption: newOption });
    if (this.props.onTimerSet && !isNaN(this.state.customValue * newOption)) {
      this.props.onTimerSet(
        this.state.customValue * newOption,
        true,
        newOption
      );
    }
  }

  changeCustomValue(e) {
    let newValue = parseInt(e.target.value);

    this.setState({ customValue: newValue });
    if (this.props.onTimerSet && !isNaN(newValue)) {
      this.props.onTimerSet(
        newValue * this.state.customOption,
        true,
        this.state.customOption
      );
    }
  }

  render() {
    return (
      <div>
        <div className="header-wrap startRowBottomSpacing">
          <div className="header">
            <Typography variant="h6">Tickticker</Typography>
            <Typography variant="caption">
              Meeting- und Standup-Timer
            </Typography>

            <IconButton aria-label="settings" onClick={this.set}>
              <SettingsIcon />
            </IconButton>
          </div>
        </div>

        <div className="startRowBottomSpacing">
          <FormControl variant="filled" className="longForm">
            <InputLabel>Wie lange?</InputLabel>
            <Select value={this.state.value} onChange={this.changeValue}>
              {Object.entries(this.props.timings).map(([key, value]) => (
                <MenuItem value={value} key={value}>
                  {key}
                </MenuItem>
              ))}

              <MenuItem value={CUSTOM}>{CUSTOM}</MenuItem>
            </Select>
          </FormControl>
        </div>

        <Collapse in={this.state.value === CUSTOM}>
          <div className="startRowBottomSpacing">
            <div className="shortFormRightSpacing">
              <TextField
                label="Anzahl"
                variant="filled"
                type="number"
                inputProps={{ min: "0", max: "99999", step: "1" }}
                value={this.state.customValue.toString()}
                onChange={this.changeCustomValue}
                className="shortForm"
              />
            </div>

            <FormControl variant="filled" className="shortForm">
              <InputLabel>Einheit</InputLabel>
              <Select
                value={this.state.customOption}
                onChange={this.changeCustomOptions}
              >
                {Object.entries(CUSTOM_OPTIONS).map(([key, value]) => (
                  <MenuItem value={value} key={value}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Collapse>

        <div className="controlRow">
          <Button
            variant="contained"
            color="primary"
            onClick={this.start}
            startIcon={<TimerIcon />}
            disableElevation
          >
            Starten
          </Button>
        </div>
      </div>
    );
  }
}

export default ControlStart;
